// import Alpine from '@alpinejs/csp'

// window.Alpine = Alpine
// window.Alpine.start()

/**
 * JavaScript code for showing searched text in the search box
 */
// if(window.location.search.length > 0) {
//     var q = window.location.search.substring(1).split('&').filter(function(x) {
//         return x.substring(0, 2) === 'q=';
//     })[0].substring(2);

//     if (q.length > 0) {
//         document.querySelector('input[name="q"]').value = q;
//     } 
// }



document.addEventListener('alpine:init', () => {
    // Handles mobile menu
    window.Alpine.data(
        'menu', () => ({
            open:false,
            toggle: () => {
                return !this.open
            },
            triggerOpen: {
                ['@click'](){
                    this.open = true
                }                    
            },
            triggerClose: {
                ['@click'](){
                    this.open = false
                }                    
            },
            trigger: {
             ['@click'](){
                 this.open = !this.open
             }
            },
            dialog:{
                ['x-show']() {
                    return this.open
                },
            },
            dialogReversed:{
                ['x-show']() {
                    return !this.open
                },
            }
        }))


        window.Alpine.data(
            'header-cover', () => ({
                init(){
                    let src = this.$el.getAttribute("data-src");

                    this.$el.style.backgroundImage ="url("+src+")"
                }
            }))

            window.Alpine.data(
                'news-getter', () => ({
                    rssfeed: [],
                    init(){
                        fetch('https://news.msutexas.edu/index.rss')
                        .then(response => response.text())
                        .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
                        .then(data => this.rssfeed =  [].slice.call(data.querySelectorAll('item')).slice(0,6))
                        this.$watch('rssfeed', () => {
                            let template = this.$refs.newstemplate
                            this.rssfeed.forEach(element => {
                                var cloned = template.cloneNode(true).content
                                var link = element.querySelector('link').innerHTML
                                var img  = element.querySelector('featured_image').innerHTML
                                var title  = element.querySelector('title').innerHTML
                                cloned.querySelector('a').href = link
                                cloned.querySelector('img').src = img
                                cloned.querySelector('p').innerHTML = title
                                this.$el.appendChild(cloned);
                            });
                        })
                    }
                }))


        window.Alpine.data(
            'hover-event-text-box', () => ({
                hovered: false,
                trigger: {
                    ['x-on:mouseover'](){
                        this.hovered = true
                    },
                    ['x-on:mouseout'](){
                        this.hovered = false
                    },
                },
                dialog: {
                    [':class'](){
                        if(this.hovered)
                            return 'dark:border-gold-500'
                        else
                            return 'border-transparent'
                    }
                }
            })
        )

        window.Alpine.data(
            'hover-event-feature-lg', () => ({
                hovered: false,
                trigger: {
                    ['x-on:mouseover'](){
                        this.hovered = true
                    },
                    ['x-on:mouseout'](){
                        this.hovered = false
                    },
                },
                dialogImg: {
                    [':class'](){
                        if(this.hovered)
                            return 'scale-105'
                        else
                            return ''
                    }
                },
                dialogText: {
                    [':class'](){
                        if(this.hovered)
                            return '-translate-y-14'
                        else
                            return ''
                    }
                },
                dialogReadMore: {
                    [':class'](){
                        if(this.hovered)
                            return 'transition  -translate-y-0'
                        else
                            return ''
                    }
                }
            })
        )
})